<template>
  <div
    style="
    padding: 15px;"
  >
    <v-card class="px-3 py-2">
      <v-row>
        <v-col
          cols="12"
          class="d-flex"
          style="position:relative;padding:0 20px;text-align:center;margin-top:20px;"
        >
          <h6 class="indigo--text" style="font-weight:bold;margin:auto;">
            DAFTAR PRODUCT
            <v-tooltip bottom color="grey">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  x-small
                  text
                  icon
                  color="grey darken-2"
                  @click="refreshPage"
                >
                  <v-icon small>mdi-refresh</v-icon>
                </v-btn>
              </template>
              <span>refresh page</span>
            </v-tooltip>
          </h6>
        </v-col>
        <v-col cols="12">
          <div>
            <v-toolbar flat style="height: 60px; border-radius: 10px 10px 0 0">
              <v-btn
                @click="addProduct"
                outlined
                elevation="1"
                color="indigo"
                class="indigo--text font-weight-bold"
                style="font-size:12px;margin-top:5px;"
                :disabled="loading"
              >
                + Tambah Data
              </v-btn>
              <div
                style="width: 275px; display: flex; flex-direction: row; margin-left: auto;margin-right:15px; "
              >
                <v-text-field
                  v-model="paramApi.keyword"
                  @keyup.enter="searchEnter"
                  :disabled="loading"
                  label="Cari"
                  type="search"
                  outlined
                  dense
                  append-icon="mdi-magnify"
                  style="position: relative; height:40px;"
                ></v-text-field>
              </div>
            </v-toolbar>
            <v-toolbar flat style="height: 60px; border-radius: 10px 10px 0 0">
              <div style="width: 120px; display: flex; flex-direction: row;">
                <v-select
                  :disabled="selectedData.length === 0"
                  label="Action"
                  style="
                      position: relative;
                      top: 15px;
                      font-size: 12px;
                    "
                  v-model="actionValue"
                  :items="[
                    { id: 0, name: '' },
                    { id: 1, name: 'Delete' }
                  ]"
                  item-text="name"
                  item-value="id"
                  outlined
                  return-id
                  dense
                  @change="viewAction"
                ></v-select>
              </div>
            </v-toolbar>
          </div>
        </v-col>

        <v-col cols="12">
          <v-data-table
            v-model="selectedData"
            item-key="id"
            mobile-breakpoint="0"
            fixed-header
            height="60vh"
            :headers="headers"
            style="cursor: pointer;"
            :items="result"
            :loading="loading"
            @click:row="rowClick"
            :footer-props="{
              showFirstLastPage: true,
              showCurrentPage: true,
              itemsPerPageOptions: [1, 3, 5, 10, 15, 100]
            }"
            :server-items-length="totalData"
            @update:page="updatePage"
            @update:items-per-page="updateItemPerPage"
            show-select
            :single-select="true"
          >
          </v-data-table>
        </v-col>
      </v-row>
    </v-card>

    <v-dialog v-model="addDialog" width="500" persistent>
      <v-form
        v-if="form !== null"
        :disabled="loading || (form.act === 'update' && !isEdit)"
        lazy-validation
        ref="entryForm"
        @submit.prevent="submit"
        style="position: relative;"
      >
        <v-card>
          <v-card-title class="text-h5 grey lighten-2">
            Formulir Tambah / Edit
          </v-card-title>

          <v-card-text
            class="d-flex justify-center flex-column"
            style="margin-top:10px;"
          >
            <v-row no-gutters>
              <v-col
                cols="12"
                style="padding: 0;display:flex;justify-content:center;align-items:center;margin-bottom:10px;"
              >
                <div class="d-flex" style="padding: 0 10px;width:100%;">
                  <v-col
                    cols="12"
                    style="padding: 0; justify-content:flex-start; align-items:center; display:flex;"
                  >
                    <div style="margin-left:auto;" v-if="form.act === 'update'">
                      <a
                        v-if="!isEdit"
                        href=""
                        @click.prevent="isEdit = true"
                        style="font-size:14px;"
                        class="d-flex flex-row"
                      >
                        <v-icon small color="#1976d2" style="margin-right:3px;">
                          mdi-application-edit-outline
                        </v-icon>
                        Edit data
                      </a>
                      <p
                        v-else
                        @click.prevent="isEdit = false"
                        style="font-size:14px; margin:0; color:red; cursor: pointer;"
                        class="d-flex flex-row"
                      >
                        Batal
                        <v-icon small color="red" style="margin-left:3px;">
                          mdi-close
                        </v-icon>
                      </p>
                    </div>
                  </v-col>
                </div>
              </v-col>

              <v-col cols="12" style="padding: 0;">
                <div style="padding: 0 10px;">
                  <v-col cols="12" style="padding: 0">
                    <p
                      style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                    >
                      Nama
                    </p>
                  </v-col>
                  <v-col cols="12" style="padding: 0;">
                    <v-text-field
                      v-model="form.name"
                      :rules="rules.nameRules"
                      dense
                      outlined
                    />
                  </v-col>
                </div>
              </v-col>
              <v-col cols="12" style="padding: 0">
                <div style="padding: 0 10px;">
                  <v-col cols="12" style="padding: 0">
                    <p
                      style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                    >
                      Code
                    </p>
                  </v-col>
                  <v-col cols="12" style="padding: 0;">
                    <v-text-field
                      v-model="form.code"
                      :rules="rules.codeRules"
                      dense
                      outlined
                    />
                  </v-col>
                </div>
              </v-col>
              <v-col cols="12" style="padding: 0">
                <div style="padding: 0 10px;">
                  <v-col cols="12" style="padding: 0">
                    <p
                      style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                    >
                      Satuan
                    </p>
                  </v-col>
                  <v-col cols="12" style="padding: 0;">
                    <v-autocomplete
                      label="Pilih"
                      v-model="form.unit"
                      :items="dropdown.unit"
                      :rules="rules.unitRules"
                      item-text="name"
                      item-value="id"
                      outlined
                      return-object
                      dense
                    ></v-autocomplete>
                  </v-col>
                </div>
              </v-col>
              <v-col cols="12" style="padding: 0">
                <div style="padding: 0 10px;">
                  <v-col cols="12" style="padding: 0">
                    <p
                      style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                    >
                      Spesifikasi
                    </p>
                  </v-col>
                  <v-col cols="12" style="padding: 0;">
                    <v-text-field v-model="form.spesification" dense outlined />
                  </v-col>
                </div>
              </v-col>
            </v-row>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-btn
              @click="closeDialog"
              color="error"
              text
              outlined
              type="reset"
              :loading="loading"
            >
              close
            </v-btn>
            <v-spacer />
            <v-btn
              color="primary"
              text
              outlined
              type="submit"
              :loading="loading"
              :disabled="form.act === 'update' && !isEdit"
            >
              Simpan
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>
<script>
import axios from 'axios'
// import { mapGetters, mapActions } from 'vuex'
import buildType from '../../../services/buildType'
export default {
  name: 'msproduct',
  components: {},
  data: () => ({
    proc: buildType.apiURL('proc'),
    expedition: buildType.apiURL('expedition'),
    eSanqua: buildType.apiURL('esanqua'),
    build: process.env.VUE_APP_BUILD_TYPE,
    loading: false,
    totalData: 0,
    result: [],
    paramApi: {
      keyword: '',
      offset: 0,
      limit: 10,
      itemsPerPage: 10,
      page: 1,
      orderBy: 'name',
      orderType: 'asc'
    },
    addDialog: false,
    form: null,
    isEdit: false,
    options: {},

    headers: [
      {
        text: 'Code',
        value: 'code',
        align: 'left',
        sortable: false
      },
      {
        text: 'Nama',
        value: 'name',
        align: 'left',
        sortable: false
      },
      {
        text: 'unit',
        value: 'unit.name',
        align: 'left',
        sortable: false
      }
    ],
    enableWatcher: true,
    selectedData: [],
    actionValue: 0,
    dropdown: {
      unit: []
    },
    rules: {
      nameRules: [],
      codeRules: [],
      unitRules: []
    }
  }),
  async mounted() {
    this.getDataFromApi()
    this.dropdownUnit()
  },
  methods: {
    viewAction(event) {
      switch (event) {
        case 1:
          this.deleteItem()
          break
      }
    },
    setAction() {
      this.actionValue = 0
      this.selectedData = []
    },
    refreshPage() {
      this.getDataFromApi()
    },
    async getDataFromApi() {
      this.result = []
      this.loading = true
      await this.initDataTable().then(data => {
        setTimeout(() => {
          this.result = data.data
          this.totalData = data.total_record
          this.loading = false
        }, 1000)
      })
    },
    async dropdownUnit() {
      this.dropdown.unit = []
      await axios
        .get(`${this.proc}master/unit/drop_down?keyword=`)
        .then(res => {
          if (res.data.status_code === '00') {
            const arr = res.data.data
            return (this.dropdown.unit = arr)
          }
          return (this.dropdown.unit = [])
        })
        .catch(err => {
          console.log(err)
          return (this.dropdown.unit = [])
        })
    },
    async initDataTable() {
      var url = `${this.expedition}product/list?keyword=${
        this.paramApi.keyword
      }&offset=${this.paramApi.offset * this.paramApi.limit}&limit=${
        this.paramApi.limit
      }`

      return await new Promise(resolve => {
        axios
          .get(url)
          .then(res => {
            console.log(res)
            resolve(res.data)
          })
          .catch(err => {
            console.log(err)
          })
      })
    },
    rowClick(pItem) {
      this.setForm(pItem)

      setTimeout(() => {
        this.addDialog = true
      }, 300)
    },
    addProduct() {
      this.setForm()
      setTimeout(() => {
        this.addDialog = true
      }, 300)
    },
    submit() {
      this.rules = {
        nameRules: [v => !!v || 'Name is required'],
        unitRules: [v => !!v || 'Unit is required'],
        codeRules: [v => !!v || 'Code is required']
      }
      const self = this
      setTimeout(function() {
        if (self.$refs.entryForm.validate()) {
          self.createNewForm()
        }
      })
    },
    createNewForm() {
      const form = {
        act: this.form.act,
        name: this.form.name,
        code: this.form.code,
        unit_id: this.form.unit.id,
        unit_name: this.form.unit.name,
        spesification: this.form.spesification
      }
      console.log(form)
      if (this.form.act === 'update') {
        Object.assign(form, { id: this.form.id })
      }
      this.save(form)
    },
    async save(form) {
      this.loading = true
      await axios
        .post(`${this.expedition}product/save`, form)
        .then(res => {
          this.loading = false
          if (res.data.status_code !== '-99') {
            const self = this
            setTimeout(() => {
              self.closeDialog()
              this.getDataFromApi()
            }, this.showMsgDialog('success', res.data.status_msg, false))
          } else {
            if (res.data.status_msg === 'Parameter value has problem') {
              return this.showMsgDialog(
                'error',
                'Invalid input, Parameter value has problem',
                false
              )
            } else {
              return this.showMsgDialog('error', res.data.status_msg, false)
            }
          }
        })
        .catch(err => {
          this.showMsgDialog(
            'error',
            err
              ? 'Something went wrong, Please contact an admin!'
              : 'Something went wrong, Please contact an admin!',
            false
          )
          return (this.loading = false)
        })
    },
    closeDialog() {
      this.addDialog = false
      this.$refs.entryForm.reset()
      this.form = null
    },
    async deleteItem() {
      this.showMsgDialog(
        'question',
        'Anda yakin ingin menghapus data ini sekarang',
        true
      ).then(async res => {
        if (res.isConfirmed) {
          await axios
            .delete(
              `${this.expedition}product/delete/${this.selectedData[0].id}`
            )
            .then(res => {
              if (res.data.status_code === '00') {
                this.showMsgDialog('success', res.data.status_msg, false)
                this.setAction()
                this.getDataFromApi()
              } else {
                this.showMsgDialog('warning', res.data.status_msg, false)
              }
            })
            .catch(err => {
              console.log(err)
              this.showMsgDialog('error', err, false)
            })
        } else {
          this.actionValue = 0
        }
      })
    },
    searchEnter() {
      this.getDataFromApi()
    },
    async updatePage(p) {
      this.paramApi.page = p
      this.paramApi.offset = p - 1
      await this.getDataFromApi()
    },
    async updateItemPerPage(p) {
      this.paramApi.limit = p
      this.paramApi.offset = 0
      await this.getDataFromApi()
    },
    setForm(xData) {
      console.log(xData)
      const x = xData !== undefined
      this.form = {
        act: x ? 'update' : 'add',
        name: x ? xData.name : '',
        code: x ? xData.code : '',
        spesification: x ? xData.spesification : '',
        unit: x ? xData.unit : null
      }
      if (x) {
        Object.assign(this.form, { id: xData.id })
      }
    },
    showMsgDialog(pModalType, pStatusMsg, pBtnCancel) {
      return new Promise(resolve => {
        this.$swal
          .fire({
            position: 'center',
            icon: pModalType,
            html: '<strong style="font-size:18px;">' + pStatusMsg + '</strong>',
            showConfirmButton: true,
            showCancelButton: pBtnCancel,
            cancelButtonText: 'No'
          })
          .then(r => {
            resolve(r)
          })
      })
    }
  }
}
</script>
<style scoped lang="scss">
@media (max-width: 576px) {
}
@media (max-width: 320px) {
}
</style>
